@import url('https://fonts.googleapis.com/css2?family=ZCOOL+QingKe+HuangYou&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Iceberg&family=ZCOOL+QingKe+HuangYou&display=swap');

@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"); 
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components
{
  .navbutt
  {
    @apply h-full mx-5 hover:text-betterColors-darkerGreen;
  }

  .formText
  {
    @apply text-black w-full p-3 rounded-lg border-2 border-betterColors-darkCyan font-myFont;
  }

  .divBasicFormat
  {
    @apply flex justify-center text-white xl:mx-48 mx-10 mb-10;
  }

  .baseComponentBackground
  {
    @apply w-full rounded-md bg-betterColors-grayCyan font-myFont
  }

  .whiteTextShadow
  {
    text-shadow: 0px 0px 0px white;
  }
}